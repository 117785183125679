:global(#app) {
  .controls {
    clear: both;
    margin-top: 6px;
  }

  .field {
    background: #fff;
    color: #17394d;
    display: block;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 4px;
    overflow: hidden;
    resize: none;

    &:focus {
      outline: none;
    }
  }
  .display {
    cursor: pointer;
  }

  .formattedField {
    margin-bottom: 15px;
    font-weight: bold;
  }

  .field {
    margin-bottom: 10px;
  }
}
